<script>
export default {
  data() {
    return {
      viewAllText: "See all projects",
      projects: [
        {
          title: "MADE DIGITAL BRAND BOOK",
          year: 2024,
          details: "BRANDING / UI / UX / MOTION",
          image: "/assets/images/covers/coverimage.png", // path to your image
          link: "/made-digital-brand-book",
        },
        {
          title: "KRONOLITH",
          year: 2024,
          details: "INTERFACE / Product Design / 3D Animation",
          image: "/assets/images/covers/coverimage-1.png",
          link: "/kronolith",
        },
        {
          title: "TINY HOUSE",
          year: 2023,
          details: "BRANDING / SOCIAL CONTENT",
          image: "/assets/images/covers/coverimage-4.png",
          link: "/tinyhouse",
        },
        {
          title: "BUILDR",
          year: 2023,
          details: "Branding / INTERFACE / UX",
          image: "/assets/images/covers/coverimage-2.png", // path to your image
          link: "/buildr",
        },
        {
          title: "TRANSIT",
          year: 2020,
          details: "ART / SCULPTURE / INDUSTRIAL DESIGN",
          image: "/assets/images/projects/transit/transit-loop-animation-sm.gif",
          link: "/transit",
        },
        // Add more projects as needed
      ],
      hoveredImage: null,
      imageStyles: {
        top: '0px',
        left: '0px',
      },
    };
  },
  methods: {
    showImage(image, event) {
      this.hoveredImage = image;
      this.updateImagePosition(event);
    },
    hideImage() {
      this.hoveredImage = null;
    },
    moveImage(event) {
      this.updateImagePosition(event);
    },
    updateImagePosition(event) {
      const offset = 20; // Offset the image from the mouse pointer
      this.imageStyles = {
        top: `${event.clientY - offset }px`,
        left: `${event.clientX + offset}px`,
      };
    },
  },
};
</script>


<template>
  <section class="mt-md">
  <!-- TITLE BOX-->
  <div class="selectedwork-title-box">
    <p class="selectedwork-title-text txt-nt-100">Selected work</p>
  </div>  

  <!-- WORK LIST-->
  <div class="work-list">
    <router-link
      v-for="(item, index) in projects"
      :key="index"
      :to="item.link"
      class="row"
      @mouseenter="showImage(item.image, $event)"
      @mouseleave="hideImage"
      @mousemove="moveImage($event)"
    >
      <div class="row-content">
        <div class="divider"></div>
        <div class="top-row"><span class="year txt-nt-60">{{ item.year }}</span></div>
        <div class="middle-row"><span class="title txt-nt-100">{{ item.title }}</span></div>
        <div class="bottom-row"><span class="details txt-nt-60">{{ item.details }}</span></div>
        <div class="divider"></div>
      </div>
    </router-link>

    <!-- Image container that appears on hover -->
    <transition name="image-fade">
      <div v-if="hoveredImage" :style="imageStyles" class="hover-image">
        <img :src="hoveredImage" alt="Project image" />
      </div>
    </transition>
  </div>
  
  <!-- LINK BOX -->
  <div class="selectedwork-title-box">
    <router-link class="view-button-box" to="/work">
      <img class="link-arrow" src="/assets/arrow-right-link.png" alt="arrow">
      <span class="link-text">{{ viewAllText }}</span>
    </router-link>
  </div>  

</section>
</template>

<style scoped lang="scss">



</style>
